import React, { useState, useRef, useContext, useEffect } from 'react';
import { AddNewOrderContext } from '../../../pages/e-commerce/AddNewOrder';
import { updateGLobalBoxFieldsData } from '../../../../functions/boxFieldsFunctions/updateGLobalBoxFieldsData';
import { getActualBoxFieldState } from '../../../../functions/boxFieldsFunctions/getActualBoxFieldData';
import { getBoxFieldContent } from '../../../../functions/boxFieldsFunctions/getBoxFieldContent';
import TextEditor from './TextEditor';
import { isNumeric } from '../../../../functions/isNumeric';
import { useSearchParams } from 'react-router-dom';
import { EDITOR_TYPES } from './TextEditor';

const InputText = ({
  colorPicker,
  description,
  boxFieldID,
  actualVariant,
  settings,
  isPriceField,
  setRegularPrice,
  setPromoPrice,
  fieldRole,
  isPlainText
}) => {
  const { boxFieldsData, setBoxFieldsData } = useContext(AddNewOrderContext);
  const boxRef = useRef();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState(false);
  const [initialValue, setInitialValue] = useState('');

  useEffect(() => {
    if (fieldRole === 'correction_text' && searchParams.get('is_correction')) {
      handleInputText(''); //CLEAN CORRECTION FIELD INPUT
    }

    setInitialValue(getBoxFieldContent(boxFieldsData, boxFieldID, actualVariant));
  }, []);

  const textAreaHeightGenerator = () => {
    let height;
    if (settings.size === 1) height = 60;
    if (settings.size === 1 && description) height = 26;
    if (settings.size === 2) height = 150;
    if (settings.size === 3) height = 300;

    return height;
  };

  const handleInputText = (content) => {
    //method for handle onlu TINYMCE
    const copyInputData = {
      ...getActualBoxFieldState(boxFieldsData, boxFieldID, actualVariant),
      value: { content: content }
    };
    updateGLobalBoxFieldsData(copyInputData, boxFieldsData, setBoxFieldsData, boxFieldID, actualVariant);
  };

  const handleNumericInput = (e) => {
    //handle numeric inputs
    if (isNumeric(e.target.value)) {
      const copyInputData = {
        ...getActualBoxFieldState(boxFieldsData, boxFieldID, actualVariant),
        value: { content: e.target.value }
      };
      updateGLobalBoxFieldsData(copyInputData, boxFieldsData, setBoxFieldsData, boxFieldID, actualVariant);
      if (settings.id === 'promo_price') setPromoPrice(e.target.value);
      if (description === 'cena omnibus') setRegularPrice(e.target.value);

      if (error) setError(false);
    } else {
      setError(true);
    }
  };

  const textAreaClassGenerator = () => {
    let result = 'content input-text-box ';

    if (colorPicker) result += ' p-l ';
    if (settings.display === 'inline') result += 'position-inline';
    return result;
  };

  const textAreaPaddingGenerator = () => {
    if (settings.size === 1) {
      return '5px 10px';
    } else {
      return '5px 10px';
    }
  };
  const [isFocused, setIsFocused] = useState(false);
  return (
    <>
      <div className={textAreaClassGenerator()} ref={boxRef}>
        <span className="field-desc">{isPriceField ? description || '' : ''}</span>
        {['price'].includes(settings.type) ? (
          <div className="input-wrapper">
            <textarea
              className="price-input"
              placeholder={settings.placeholder}
              style={{ height: textAreaHeightGenerator() + 'px' }}
              value={getBoxFieldContent(boxFieldsData, boxFieldID, actualVariant) || ''}
              onChange={handleNumericInput}
            ></textarea>
            {error && (
              <span className="error">
                *Podana wartość musi być wartością liczbową i posiadać kropkę zamiast przecinka
              </span>
            )}
          </div>
        ) : isPlainText ? (
          <div className="input-wrapper">
            <textarea
              className="price-input"
              placeholder={settings.placeholder}
              style={{ height: textAreaHeightGenerator() + 'px' }}
              value={getBoxFieldContent(boxFieldsData, boxFieldID, actualVariant) || ''}
              onChange={(e) => handleInputText(e.target.value)}
            ></textarea>
          </div>
        ) : (
          <TextEditor
            saveData={handleInputText}
            initialValue={initialValue ?? ''}
            placeholder={settings.placeholder}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            editorType={isPriceField ? EDITOR_TYPES.BUBBLE : EDITOR_TYPES.SNOW}
            style={
              isPriceField
                ? {
                    border: isFocused ? '1px solid #1ab394' : '1px solid #E4E5E6',
                    width: '100%',
                    height: textAreaHeightGenerator(),
                    background: '#FFF',
                    borderRadius: '8px',
                    minHeight: '30px',
                    paddingLeft: '13px',
                    '&:focus': {
                      border: '1px solid #1ab394'
                    },
                    marginTop: '0',
                    padding: textAreaPaddingGenerator(),
                    verticalAlign: 'center',
                    fontFamily: `"Open Sans", sans-serif`,
                    fontSize: `12px`
                  }
                : {}
            }
          />
        )}
      </div>
    </>
  );
};

export default InputText;
