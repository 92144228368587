import React from 'react';
import substringByLength from '../../../../../functions/trimString';
import PERMISSION_ENUM from '../ENUMS/TEAN_PERMISSIONS';
import ENUMS from '../ENUMS';

import { ReactComponent as DateIcon } from '../../../../../assets/team/date.svg';
import PERMISSIONS_ENUMS from '../../../../../globalEnums/PERMISSIONS_ENUMS';

const AssignedProjectBox = ({
  id,
  data,
  initializeNewWorkLog,
  setOrderId,
  orderId,
  startProjectRef,
  permission,
  projectAdditionalActionsRef,
  showAction,
  hasMarginRight,
  userType
}) => {
  const handleOrder = (localOrderID) => {
    if (orderId !== localOrderID) {
      setOrderId(localOrderID);

      if (projectAdditionalActionsRef) {
        projectAdditionalActionsRef.current.forEach((el) => {
          if (el) el.classList.remove('details-height');
        });
      }

      if (startProjectRef) {
        startProjectRef.current.forEach((el) => {
          if (el) {
            el.classList.remove('show');
            if (el.dataset.order == localOrderID) {
              el.classList.add('show');
            }
          }
        });
      }
    }
  };

  const displayUserName = () => {
    //decide whom name should display
    switch (userType) {
      case ENUMS.ROLE.ACCM:
        return data.assigned_to; //should see graphic
      case ENUMS.ROLE.CLIENT:
        return data.created_by; //should see himself
      case ENUMS.ROLE.GRAPHIC:
        return data.managed_by; //should see ACC
      case ENUMS.ROLE.KREACJA:
        return data.managed_by; //should see ACC
    }
  };

  return (
    <>
      <div
        className="assigned-project-box-container-around"
        data-order={id}
        style={{ marginRight: hasMarginRight ? '10px' : '0' }}
        ref={(el) => {
          startProjectRef.current.push(el);
        }}
        {...(permission == PERMISSION_ENUM.ACCM
          ? {
              onClick: () => {
                handleOrder(id);
              }
            }
          : {})}
      >
        <div className="assigned-project-box-container" onClick={(e) => handleOrder(data.id, e)}>
          <div className="top-section">
            <div className="status-wrapper">
              <div
                className="status-color"
                style={{
                  background: data?.status_label_and_color[1] || `#ffb800`
                }}
              ></div>
              <p className="status" style={{ color: data?.status_label_and_color[1] || `#ffb800` }}>
                {data?.designer_status_label || ''}
              </p>
            </div>
            <div className="date-wrapper">
              <DateIcon />
              <p className="status">{data.deadline?.content || `---`}</p>
            </div>
          </div>
          <p className="user-name">{displayUserName() || `---`}</p>
          <p className="action-name" title={data.action_name?.content || ''}>
            {substringByLength(data?.action_name?.content, 40) || `id: ${data.id}`}
          </p>
          <div className="bottom-section">
            <div className="order-data">
              <p>Formaty: {data.formats_count}</p>
              <div className="date">
                <p>Propozycja do:</p>
                {data.proposal_deadline && data.proposal_deadline.content ? (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: data.proposal_deadline.content
                    }}
                  />
                ) : (
                  <p>---</p>
                )}
              </div>
            </div>
            {showAction && (
              <div className="action">
                <div
                  className="start-project-worklog"
                  onClick={() => initializeNewWorkLog(data.id)}
                  data-order={data.id}
                >
                  Rozpocznij
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignedProjectBox;
